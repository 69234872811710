var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.$t('title.customer-order')))])])],1),_c('v-divider',{staticClass:"mb-3"}),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"ml-4 mr-4 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.customerOrders,"page":_vm.table.pageNo,"items-per-page":_vm.table.recordCount,"loading":_vm.table.loading,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"header.action",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.load()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.refresh')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":_vm.create}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.create')))])])]},proxy:true},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.orderNo),callback:function ($$v) {_vm.$set(_vm.filter, "orderNo", $$v)},expression:"filter.orderNo"}})],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.filterOrderDate}},[_c('v-icon',[_vm._v("mdi-filter")])],1)],1),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.customerCode),callback:function ($$v) {_vm.$set(_vm.filter, "customerCode", $$v)},expression:"filter.customerCode"}})],1),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.customerName),callback:function ($$v) {_vm.$set(_vm.filter, "customerName", $$v)},expression:"filter.customerName"}})],1),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.pageScopedId),callback:function ($$v) {_vm.$set(_vm.filter, "pageScopedId", $$v)},expression:"filter.pageScopedId"}})],1),_c('td',[_c('v-select',{staticClass:"mt-1 mb-2",attrs:{"items":_vm.statusOptions(true),"hide-details":"","dense":""},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1),_c('td'),_c('td')])]},proxy:true},{key:"item.customerCode",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"no-underline inherit-color",attrs:{"href":_vm.customerUrl(item),"target":"_blank"}},[_vm._v(" "+_vm._s(item.customer.code)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"to":'/management/customer/customer-orders/modify?id=' + item.id,"icon":""}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.modify')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.isToPay(item),"icon":""},on:{"click":function($event){return _vm.cancelDialog(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.cancel")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"disabled":!_vm.isDraft(item),"icon":""},on:{"click":function($event){return _vm.removeDialog(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.delete")))])])]}}])})],1)],1),_c('v-row',{staticClass:"text-center pt-2 ml-2 mr-2"},[_c('v-col',{attrs:{"sm":"3","md":"2","lg":"2","xl":"1"}},[_c('v-select',{staticClass:"mt-4 text-center",attrs:{"label":_vm.$t('text.record-count'),"items":_vm.recordCounts,"dense":""},on:{"input":function($event){_vm.table.pageNo = 1; _vm.load();}},model:{value:(_vm.table.recordCount),callback:function ($$v) {_vm.$set(_vm.table, "recordCount", $$v)},expression:"table.recordCount"}})],1),_c('v-col',[_c('v-pagination',{attrs:{"length":_vm.table.pageCount,"total-visible":"12"},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.table.pageNo),callback:function ($$v) {_vm.$set(_vm.table, "pageNo", $$v)},expression:"table.pageNo"}})],1)],1)],1),(_vm.customerOrderCreateDialog.visible)?_c('CustomerOrderCreateDialogView',{attrs:{"properties":_vm.customerOrderCreateDialog,"customerOrderCustomer":_vm.customerOrderCustomer,"alertDialog":_vm.alertDialog}}):_vm._e(),(_vm.customerOrderCustomer.visible)?_c('CustomerOrderCustomerView',{attrs:{"properties":_vm.customerOrderCustomer,"alertDialog":_vm.alertDialog}}):_vm._e(),(_vm.dateFilterDialog.visible)?_c('DateFilterDialogView',{attrs:{"properties":_vm.dateFilterDialog},on:{"filtered":_vm.filteredDate}}):_vm._e(),(_vm.alertDialog.visible)?_c('AlertDialogView',{attrs:{"properties":_vm.alertDialog},on:{"clicked":_vm.alertDialogClicked}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }


















































































































































































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerOrderCreateDialogProperties } from "./CustomerOrderCreateDialogView.vue";
import { DateFilterDialogProperties } from '@/components/shared/DateFilterDialogView.vue';
import { CustomerOrderCustomerProperties } from './CustomerOrderCustomerView.vue';
import ListViewMixin from "@/components/shared/ListViewMixin";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import RouteUtil from "@/utilities/RouteUtil";
import StringUtil from '@/utilities/StringUtil';
import FilterUtil from "@/utilities/FilterUtil";
import ResponseUtil from '@/utilities/ResponseUtil';
import DateUtil from '@/utilities/DateUtil';
import CustomerOrderService from "@/services/CustomerOrderService";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const CustomerOrderCreateDialogView = () => import("./CustomerOrderCreateDialogView.vue");
const CustomerOrderCustomerView = () => import("./CustomerOrderCustomerView.vue");
const DateFilterDialogView = () => import("@/components/shared/DateFilterDialogView.vue");

@Component({
    components: { 
        AlertDialogView,
        CustomerOrderCreateDialogView, 
        CustomerOrderCustomerView, 
        DateFilterDialogView 
    },
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            loading: false,
        },
        filter: {
            orderNo: "",
            orderDateFrom: null,
            orderDateTo: null,
            customerCode: "",
            customerName: "",
			pageScopedId: "",
            status: "All"
        },
        customerOrders: [],
    }),
})
export default class CustomerOrderListView extends Mixins(ListViewMixin) {
    private alertDialog = new AlertDialogProperties();
    private customerOrderCreateDialog = new CustomerOrderCreateDialogProperties();
    private customerOrderCustomer = new CustomerOrderCustomerProperties();
    private dateFilterDialog = new DateFilterDialogProperties();
    private customerOrderService = new CustomerOrderService();
    private dateTimeFormat: string = null;
    private dateFormat: string = null;

    public get headers() {
        return [
            {
                text: this.$t("text.order-no"),
                value: "uniqueNo",
                width: "12%",
            },
            {
                text: this.$t("text.order-date"),
                value: "formatted.orderDate"
            },
            {
                text: this.$t("text.customer-code"),
                value: "customerCode",
				width: "12%"
            },
            {
                text: this.$t("text.customer-name"),
                value: "formatted.name"
            },
			{
				text: this.$t("text.psid"),
				value: "customer.facebookAccount.pageScopedId"
			},
            {
                text: this.$t("text.status"),
                value: "formatted.status",
                width: "200px"
            },
            {
                text: this.$t("text.total-amount"),
                value: "formatted.totalAmount",
                align: "right"
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                fixed: true,
                align: "center",
                width: "160px",
                sortable: false,
            },
        ];
    }

    public statusOptions(withAll: boolean = false) {
        return [
            {
                text: this.$t('text.all'),
                value: "All",
                all: true
            },
            {
                text: this.$t('text.draft'),
                value: "Draft",
                all: false
            },
            {
                text: this.$t('text.to-pay'),
                value: "To-Pay",
                all: false
            },
            {
                text: this.$t('text.checkout'),
                value: "Checkout",
                all: false
            },
            {
                text: this.$t('text.paid'),
                value: "Paid",
                all: false
            },
            {
                text: this.$t('text.approved'),
                value: "Approved",
                all: false
            },
            {
                text: this.$t('text.delivered'),
                value: "Delivered",
                all: false
            },
            {
                text: this.$t('text.cancelled'),
                value: "Cancelled",
                all: false
            },
            {
                text: this.$t('text.expired'),
                value: "Expired",
                all: false
            },
            {
                text: this.$t('text.rejected'),
                value: "Rejected",
                all: false
            },
            {
                text: this.$t("text.transferring"),
                value: "Transferring",
                all: false
            }
        ].filter(x => !x.all || withAll);
    }

    public isDraft(item: any) {
        return item.status === "Draft";
    }

    public isToPay(item: any) {
        return item.status === "To-Pay";
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public formatStatus(v: any) {
        return this.statusOptions().find(x => x.value === v).text;
    }

	public customerUrl(item: any) {
		return `/management/customers/modify?id=${item.customer.id}`;
	}

    public created() {
        this.customerOrderCreateDialog.events.subscribe('created', this.createdRecord);
        this.listenVisibleChanged();

        const data = this.$data;
        const query = this.$route.query;

        data.table.pageNo = parseInt(query.pageNo as any ?? 1);
        data.table.recordCount = parseInt(query.recordCount as any ?? 10);

        data.filter.orderNo = query.orderNo ?? "";
        data.filter.orderDateFrom = FilterUtil.dateOf(query.orderDateFrom);
        data.filter.orderDateTo = FilterUtil.dateOf(query.orderDateTo);
        data.filter.customerCode = query.customerCode ?? "";
        data.filter.customerCode = query.customerName ?? "";
		data.filter.pageScopedId = query.pageScopedId ?? "";
        data.filter.status = query.status ?? "All";

        this.load();
    }

    public destroyed() {
        this.customerOrderCreateDialog.events.remove('created', this.createdRecord);
        this.removeVisibleChanged();
    }

    public visibleChanged(e) {
        if (!document.hasFocus()) this.load();
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        try {
            const filter = this.$data.filter;
            const status = filter.status === 'All' ? null : filter.status;

            const r = await this.customerOrderService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                uniqueNo: StringUtil.enclose(filter.orderNo, '%', '%'),
                orderDate: FilterUtil.filterDate(false, filter.orderDateFrom, filter.orderDateTo),
                customerCode: StringUtil.enclose(filter.customerCode, '%', '%'),
				pageScopedId: StringUtil.enclose(filter.pageScopedId, '%', '%'),
                status,
				searchCustomer: filter.customerName,
                loadCustomer: true,
				loadFacebookAccount: true
            });
            this.dateFormat = ResponseUtil.getDateFormat(r);
            this.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);

            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            const customerOrders: any[] = r.data.customerOrders;
			customerOrders.forEach(e => {
				e.totalAmount ??= 0;

				const c = e.customer;
				const a = c.facebookAccount;
				const n = c.name;
				const u = a.uniqueNo ?? null;

				e.formatted = {
					orderDate: this.formatDate(e.orderDate),
					status: this.formatStatus(e.status),
					name: u !== null ? `${n} [${u}]` : n,
					totalAmount: e.totalAmount.toFixed(2)
				};
			});

            this.$data.customerOrders = customerOrders.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }

            const url = RouteUtil.makeUrl("/management/customer/customer-orders", {
                pageNo: this.$data.table.pageNo,
                recordCount: rowCount,
                uniqueNo: filter.orderNo,
                orderDateFrom: filter.orderDateFrom,
                orderDateTo: filter.orderDateTo,
                customerCode: filter.customerCode,
                customerName: filter.customerName,
				psid: filter.pageScopedId,
                status
            });
            if (this.$route.fullPath !== url) {
                await this.$router.replace(url);
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public async remove(record: any) {
         try {
            await this.customerOrderService.delete(record);
            await this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async cancel(record: any) {
        try {
            await this.customerOrderService.cancel(record);
            await this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public create() {
        this.customerOrderCreateDialog.visible = true;
    }

    public async createdRecord(record: any) {
        await this.$router.push("/management/customer/customer-orders/modify?id=" + record.id);
    }

    public removeDialog(record: any) {
        AlertDialogProperties.delete(this.alertDialog, record.uniqueNo);
        this.alertDialog.tag = { command: "remove", record };
        this.alertDialog.visible = true;
    }

    public cancelDialog(record: any) {
        const t = this.$t("title.cancel-order");
        const m = this.$t("message.cancel-order2", [record.uniqueNo]);

        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.tag = { command: "cancel", record };
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.visible = true;
    }

    public filterOrderDate() {
        const filter = this.$data.filter;
        this.dateFilterDialog.title = this.$t('text.order-date').toString();
        this.dateFilterDialog.dateFrom = filter.orderDateFrom;
        this.dateFilterDialog.dateTo = filter.orderDateTo;
        this.dateFilterDialog.labelDateFrom = this.$t('text.date-from').toString();
        this.dateFilterDialog.labelDateTo = this.$t('text.date-to').toString();
        this.dateFilterDialog.dateFormat = this.dateFormat;
        this.dateFilterDialog.tag = 'order-date';
        this.dateFilterDialog.visible = true;
    }

    public filteredDate() {
        const filter = this.$data.filter;
        if (this.dateFilterDialog.tag === 'order-date') {
            filter.orderDateFrom = this.dateFilterDialog.dateFrom;
            filter.orderDateTo = this.dateFilterDialog.dateTo;
            this.load();
        }
    }

    public alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === 'remove' && button === this.$t('text.yes')) {
                this.remove(tag.record);
            } else if (tag.command === "cancel" && button === this.$t("text.yes")) {
                this.cancel(tag.record);
            }
        }
    }
}
